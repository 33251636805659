<template>
  <v-card>
    <v-card-text
      v-if="show"
      class="pa-1"
    >
      <v-row
        class="ma-0 pa-0"
      >
        <v-col cols="2"
          class="ma-0 pa-0 align-self-center"
        >
          <v-col cols="12" class="py-0 my-0 text-h6 text-center">
            {{ viewItem.proyecto }}
          </v-col>
          <v-col cols="12" class="text-center py-0">
            <v-progress-circular
              :rotate="-90"
              :size="150"
              :width="15"
              :value="viewItem.porcentaje_general"
              :color="getColorFromValue(viewItem.porcentaje_general)"
              style="pointer-events: none"
            >
              <div class="text-subtitle-1 font-weight-bold">
                {{ viewItem.porcentaje_general.toFixed(2) }} %
              </div>
              
            </v-progress-circular>
          </v-col>
        </v-col>
        
        <v-col cols="10">
          <v-row class="ma-0 pa-0">
            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consulta Médica
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.consulta_medica_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.consulta_medica_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.consulta_medica_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_medica_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Intervenciones Odontológicas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.intervenciones_odontologicas_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.intervenciones_odontologicas_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.intervenciones_odontologicas_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.intervenciones_odontologicas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consulta Dental
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.consulta_dental_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.consulta_dental_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.consulta_dental_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_dental_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Detecciones Químicas Secas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.detecciones_quimicas_secas_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.detecciones_quimicas_secas_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.detecciones_quimicas_secas_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.detecciones_quimicas_secas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  T/A
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.ta_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.ta_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.ta_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.ta_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Somatometría
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.somatometria_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.somatometria_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.somatometria_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.somatometria_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Ultrasonidos
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.ultrasonidos_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.ultrasonidos_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.ultrasonidos_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.ultrasonidos_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Electrocardiogramas
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.electrocardiogramas_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.electrocardiogramas_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.electrocardiogramas_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.electrocardiogramas_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pruebas Rápidas COVID-19
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.covid19_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.covid19_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.covid19_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.covid19_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Consultas de Nutrición
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.consulta_nutricion_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.consulta_nutricion_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.consulta_nutricion_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.consulta_nutricion_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas Alimentación Sana (Nutrición)
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.platicas_alimentacion_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.platicas_alimentacion_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.platicas_alimentacion_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platicas_alimentacion_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas Salud Bucal (Dental)
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.platica_salud_bucal_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.platica_salud_bucal_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.platica_salud_bucal_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platica_salud_bucal_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Acciones Salud Bucodental
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.acciones_salud_bucocal_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.acciones_salud_bucocal_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.acciones_salud_bucocal_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.acciones_salud_bucocal_texto }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="py-1">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 font-weight-bold">
                  Pláticas de Promoción a la Salud
                </v-col>
                <v-col cols="7" class="ma-0 pa-0 align-self-end pr-1">
                  <v-progress-linear
                  v-model="viewItem.platicas_promocion_salud_porcentaje"
                  :height="height_progress"
                  :color="getColorFromValue(viewItem.platicas_promocion_salud_porcentaje)"
                  style="pointer-events: none"
                >
                  <strong>{{ viewItem.platicas_promocion_salud_porcentaje.toFixed(2) }}%</strong>
                </v-progress-linear>
                </v-col>
                <v-col cols="5" class="ma-0 pa-0 pl-1 text-center">
                  {{ viewItem.platicas_promocion_salud_texto }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    show: true,
    height_progress: 15,
    viewItem: {
      proyecto: "",
      porcentaje_general: 0.0,
      consulta_medica_porcentaje: 0.0,
      intervenciones_odontologicas_porcentaje: 0.0,
      consulta_dental_porcentaje: 0.0,
      detecciones_quimicas_secas_porcentaje: 0.0,
      ta_porcentaje: 0.0,
      somatometria_porcentaje: 0.0,
      ultrasonidos_porcentaje: 0.0,
      electrocardiogramas_porcentaje: 0.0,
      covid19_porcentaje: 0.0,
      consulta_nutricion_porcentaje: 0.0,
      platicas_alimentacion_porcentaje: 0.0,
      platica_salud_bucal_porcentaje: 0.0,
      acciones_salud_bucocal_porcentaje: 0.0,
      platicas_promocion_salud_porcentaje: 0.0,
      consulta_medica_texto: "",
      intervenciones_odontologicas_texto: "",
      consulta_dental_texto: "",
      detecciones_quimicas_secas_texto: "",
      ta_texto: "",
      somatometria_texto: "",
      ultrasonidos_texto: "",
      electrocardiogramas_texto: "",
      covid19_texto: "",
      consulta_nutricion_texto: "",
      platicas_alimentacion_texto: "",
      platica_salud_bucal_texto: "",
      acciones_salud_bucocal_texto: "",
      platicas_promocion_salud_texto: "",
    },
    defaultViewItem: {
      proyecto: "",
      porcentaje_general: 0.0,
      consulta_medica_porcentaje: 0.0,
      intervenciones_odontologicas_porcentaje: 0.0,
      consulta_dental_porcentaje: 0.0,
      detecciones_quimicas_secas_porcentaje: 0.0,
      ta_porcentaje: 0.0,
      somatometria_porcentaje: 0.0,
      ultrasonidos_porcentaje: 0.0,
      electrocardiogramas_porcentaje: 0.0,
      covid19_porcentaje: 0.0,
      consulta_nutricion_porcentaje: 0.0,
      platicas_alimentacion_porcentaje: 0.0,
      platica_salud_bucal_porcentaje: 0.0,
      acciones_salud_bucocal_porcentaje: 0.0,
      platicas_promocion_salud_porcentaje: 0.0,
      consulta_medica_texto: "",
      intervenciones_odontologicas_texto: "",
      consulta_dental_texto: "",
      detecciones_quimicas_secas_texto: "",
      ta_texto: "",
      somatometria_texto: "",
      ultrasonidos_texto: "",
      electrocardiogramas_texto: "",
      covid19_texto: "",
      consulta_nutricion_texto: "",
      platicas_alimentacion_texto: "",
      platica_salud_bucal_texto: "",
      acciones_salud_bucocal_texto: "",
      platicas_promocion_salud_texto: "",
    }
  }),
  methods: {
    getColorFromValue(value) {
      if (value <= 0) {
        return 'rgb(255, 0, 0)'; // Rojo para valores menores o iguales a 0
      } else if (value >= 100) {
        return 'rgb(0, 99, 65)'; // Verde oscuro para valores mayores o iguales a 100
      } else if (value === 50) {
        return 'rgb(255, 255, 0)'; // Amarillo exacto para valor 50
      } else if (value < 50) {
        let green = Math.round(255 * (value / 50)); // Aumenta el valor de G gradualmente hasta 255
        return `rgb(255, ${green}, 0)`; // Degradado de rojo (255, 0, 0) a amarillo (255, 255, 0)
      } else if (value <= 75) {
        let red = Math.round(255 * ((75 - value) / 25)); // Degradado de 255 a 0 en R
        return `rgb(${red}, 255, 0)`; // Degradado de amarillo (255, 255, 0) a verde brillante (0, 255, 0)
      } else {
        let green = Math.round(255 - (156 * ((value - 75) / 25))); // Degradado de 255 a 99 en G
        let blue = Math.round(65 * ((value - 75) / 25)); // Degradado de 0 a 65 en B
        return `rgb(0, ${green}, ${blue})`; // Degradado de verde brillante (0, 255, 0) a verde oscuro (0, 99, 65)
      }
    },
    updateInfomation(item){
      this.show = false;
      var template = Object.assign({}, this.defaultViewItem);
      this.viewItem = Object.assign(template, item);
      this.$nextTick(() => {
          this.show = true;
      });
    }
  }
}
</script>