<template>
  <div>
    <v-btn
      block
      color="primary"
      text
      @click="openDialog"
    >
      <v-icon>
        mdi-finance
      </v-icon>
      Mostrar Metas en Tiempo Real
    </v-btn>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <transition name="fade">
          <v-toolbar
            v-if="toolbarVisible"
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Metas en Tiempo Real</v-toolbar-title>
            <v-spacer></v-spacer>
            <!--
            <v-switch
              v-model="type"
              :label="type ? 'Diario' : 'Mensual'"
              hide-details
              @change="getInformation(true)"
            >
            </v-switch>
            -->
            <v-select
              v-model="type"
              hide-details
              dense
              outlined
              item-value="id"
              item-text="text"
              :items="modalidades"
              @change="getInformation(true)"
            >
            </v-select>
          </v-toolbar>
        </transition>
        <br>
        <v-card-text
          v-if="!loading"
        >
          <v-row>
            <v-col cols="2" class="align-self-center">
              <v-img src="/img/logo_ubga.jpg" height="130px" contain />
            </v-col>
            <v-col cols="10" class="align-self-center">
              <v-card>
                <v-list-item two-line>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="text-h5">
                      {{viewItem.intervalo_texto}}
                    </v-list-item-title>
                    <v-list-item-subtitle>Hasta las {{viewItem.hasta_hora}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text class="pt-0">
                  <v-progress-linear
                    v-model="viewItem.porcentaje_global"
                    height="25"
                    rounded
                    style="pointer-events: none"
                    :color="getColorFromValue(viewItem.porcentaje_global)"
                  >
                    <strong>{{ toFixed(viewItem.porcentaje_global) }} %</strong>
                  </v-progress-linear>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br>
          <v-carousel
            v-model="position"
            cycle
            height="745"
            hide-delimiter-background	
            hide-delimiters
            interval="15000"
          >
            <v-carousel-item
              v-for="(bloque,idx) in viewItem.bloques"
              :key="`bloque_${idx}`"
              eager
            >
              <v-sheet
                height="100%"
              >
                <v-row
                  class="mx-4"
                  align="center"
                  justify="center"
                >
                  <v-col
                    v-for="(proyecto, idx2) in bloque"
                    cols="12"
                    class="ma-0 pa-0 my-1"
                  :key="`informacion_${idx}_${idx2}`"
                  >
                    <UnidadMedicaDetalles
                      :key="`proyecto_${idx}_${idx2}`"
                      :ref="`proyecto_${idx}_${idx2}`"
                    >
                    </UnidadMedicaDetalles>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-card-text
          v-if="loading"
          class="fill-height"
        >
          <v-col cols="12" class="text-center">
          <br>
          <v-progress-circular
            :size="350"
            color="primary"
            indeterminate
            :width="8"
          ></v-progress-circular>
          <br>
          <br>
          <div class="text-h2">Cargando</div>
        </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UnidadMedicaDetalles from './UnidadMedicaDetalles.vue'
import axios from 'axios'

export default {
  components: {
    UnidadMedicaDetalles
  },
  data: () => ({
    dialog: false,
    type: 1,
    loading: false,
    interval: null,
    intervalCycle: 300,
    intervalState: 0,
    viewItem: {
      porcentaje_global: 0.0,
      bloques: [],
      hasta_hora: "",
      intervalo_texto: ""
    },
    defaultViewItem: {
      porcentaje_global: 0.0,
      bloques: [],
      hasta_hora: "",
      intervalo_texto: ""
    },
    modalidades: [
      {
        id: 1,
        text: "Reporte Especifico del Día"
      },
      {
        id: 2,
        text: "Reporte General del Mes"
      },
      {
        id: 3,
        text: "Reporte Especifico del Mes"
      }
    ],
    position: 0,
    colors: [
      'primary',
    ],
    toolbarVisible: true, // Nueva propiedad para controlar visibilidad
    activityTimeout: null, // Timeout para el contador
  }),
  mounted() {
    // Escuchar eventos de actividad del usuario
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
  },
  beforeDestroy() {
    // Limpiar los eventos y el timeout cuando el componente se destruya
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    clearTimeout(this.activityTimeout);
    clearInterval(this.interval);
  },
  methods: {
    toFixed(value) {
      if (value == null || value == undefined) return "";
      return value.toFixed(2);
    },
    openDialog() {
      this.dialog = true;
      this.type = 1;
      this.startActivityTimer(); // Iniciar contador cuando se abre el diálogo
      this.getInformation();
      if(this.interval == null){
        this.interval = setInterval(() => {
          this.handleUpdateInformation();
        }, 1000);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.type = 1;
      clearTimeout(this.activityTimeout); // Limpiar timeout al cerrar
      clearInterval(this.interval);
    },
    getColorFromValue(value) {
      if (value <= 0) {
        return 'rgb(255, 0, 0)'; // Rojo para valores menores o iguales a 0
      } else if (value >= 100) {
        return 'rgb(0, 99, 65)'; // Verde oscuro para valores mayores o iguales a 100
      } else if (value === 50) {
        return 'rgb(255, 255, 0)'; // Amarillo exacto para valor 50
      } else if (value < 50) {
        let green = Math.round(255 * (value / 50)); // Aumenta el valor de G gradualmente hasta 255
        return `rgb(255, ${green}, 0)`; // Degradado de rojo (255, 0, 0) a amarillo (255, 255, 0)
      } else {
        let red = Math.round(255 * ((100 - value) / 50)); // Disminuye el valor de R gradualmente
        let green = Math.round(255 - (156 * ((value - 50) / 50))); // Degradado de 255 a 99 en G
        let blue = Math.round(65 * ((value - 50) / 50)); // Degradado de 0 a 65 en B
        return `rgb(${red}, ${green}, ${blue})`; // Degradado de amarillo (255, 255, 0) a verde oscuro (0, 99, 65)
      }
    },
    // Método para ocultar la barra de herramientas si no hay actividad
    hideToolbar() {
      this.toolbarVisible = false;
    },
    // Método para iniciar/reiniciar el contador de actividad
    startActivityTimer() {
      if (this.activityTimeout) clearTimeout(this.activityTimeout);

      this.toolbarVisible = true; // Mostrar barra de herramientas

      // Ocultar la barra después de 5 segundos sin actividad
      this.activityTimeout = setTimeout(() => {
        this.hideToolbar();
      }, 5000);
    },
    // Método para manejar eventos de movimiento o teclas
    handleUserActivity() {
      this.startActivityTimer();
    },
    handleUpdateInformation(){
      if(this.loading == true) return;
      if(this.intervalState <= 0) this.getInformation();
      else this.intervalState -= 1;

    },
    getInformation(ignore_loading = false){
      if(this.loading == true && !ignore_loading || this.type <= 0) return;

      this.loading = true;

      axios
      .get(`/Incidencias/ObtenerIncidenciasTiempoReal/${this.type}`)
      .then(response => {
        this.viewItem = response.data;
        this.intervalState = this.intervalCycle;
        this.loading = false;

        this.$nextTick(() => {
          for (let idx = 0; idx < this.viewItem.bloques.length; idx++) {
            const bloque = this.viewItem.bloques[idx];

            for (let idx2 = 0; idx2 < bloque.length; idx2++) {
              const proyecto = bloque[idx2];

              try {
                this.$refs[`proyecto_${idx}_${idx2}`][0].updateInfomation(proyecto);
              } catch (error) {
                console.log(this.$refs[`proyecto_${idx}_${idx2}`]);
              }
            }
          }
        });
        
      })
      .catch(error => {
        this.viewItem = Object.assign({}, this.defaultViewItem);
        this.intervalState = 10;
        console.log(error);
        this.loading = false;
      })
    }
  }
}
</script>
